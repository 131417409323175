<template>
    <div class="attendance-container">
        <div class="attendance-main-title">{{ $t('Employee.AttendencerReport') }}</div>
        <div class="attendance-header">
            <div class="ultis-attendance">
                <div class="btn-search">
                    <span @click="searchForEmployee()" class="material-icons"
                        >search</span
                    >
                    <input
                        type="text"
                        :placeholder="$t('Customers.SearchHistory')"
                        v-model="searchValue"
                        v-on:keyup.enter="searchForEmployee()"
                    />
                </div>
                <div class="search-container-employee">
                    <date-picker
                        @click.native="chooseTime()"
                        class="widthauto timepicker"
                        v-model="rangeTime"
                        :placeholder="rangeTimeString[0]+' ~ '+rangeTimeString[1]"
                        range
                        :open="showRangePicker"
                        v-click-outside="handleClickOutsideRangePicker"
                    >
                    </date-picker>
                </div>
                <div style="margin-left:30px">
                    <md-field class="select-area">
                        <label for="movie">{{ $t("Employee.Store") }}</label>
                        <md-select v-model="selectListGroup">
                            <md-option
                                v-for="group in listGroup"
                                v-bind:key="group.id"
                                :value="group.id"
                            >
                                {{ group.name }}
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
            </div>
            <div v-if="availableExport==true" class="btn-export-csv" @click="csvExport()">
                {{ $t('Employee.Export') }}
            </div>
            <div v-else-if="availableExport==false" class="btn-export-csv-unavailable">
                {{ $t('Employee.Export') }}
                <md-tooltip
                    md-direction="top"
                    >{{ $t("dashboard.NoData") }}</md-tooltip
                >
            </div>
        </div>
        <div class="WrapTableAttendence">
            <table id="employee-attendence">
                <tr class="tr-head">
                    <th>
                        {{ $t("Employee.EmployeeID") }}
                    </th>
                    <th>
                        {{ $t("Employee.Name") }}
                    </th>
                    <th style="cursor:pointer" @click="changeSortCheckIn(!ascendingCheckIn)">
                        {{ $t("Employee.Checkin") }}
                        <span class="material-icons" v-if="ascendingCheckIn==true" >arrow_drop_up</span>
                        <span class="material-icons" v-else>arrow_drop_down</span>
                    </th>
                    <th style="cursor:pointer" @click="changeSortCheckOut(!ascendingCheckOut)">
                        {{ $t("Employee.Checkout") }}
                        <span class="material-icons" v-if="ascendingCheckOut==true">arrow_drop_up</span>
                        <span class="material-icons" v-else>arrow_drop_down</span>
                    </th>
                    <th>
                        {{ $t("Employee.Department") }}
                    </th>
                </tr>
                <tr v-for="record in listAttendenceData" :key="record.id" style="cursor:pointer">
                    <td @click="getEmployeeData(record.attendanceID)" class="border-bottom border-left">{{ record.employeeID }}</td>
                    <td @click="getEmployeeData(record.attendanceID)" class="border-bottom ">
                        <img :src="record.image" alt="Italian Trulli" />{{
                            record.name
                        }}
                        <md-tooltip md-direction="top">{{record.name}}</md-tooltip>
                    </td>
                    <td @click="getEmployeeData(record.attendanceID)" class="border-bottom ">{{ record.checkIn }}</td>
                    <td @click="getEmployeeData(record.attendanceID)" class="border-bottom ">{{ record.checkOut }}</td>
                    <td @click="getEmployeeData(record.attendanceID)" class="border-bottom border-right">{{ record.department }}</td>
                </tr>
            </table>

            <div class="pagination">
                <div class="wrapSelectPagination">
                    <span class="seeTable">
                        {{ $t("Customers.RowPerPage") }}</span
                    >
                    <div
                        class="SelectPagination"
                        @click="chooseLimitFilter()"
                        v-click-outside="handleClickOutsideLimitFilter"
                    >
                        {{ limit }}
                        <span class="material-icons">arrow_drop_down</span>
                        <div
                            class="wrapOptionPagination"
                            ref="wrapLimitFilterOption"
                        >
                            <div
                                class="OptionActive"
                                ref="limitFive"
                                @click="changeLimit('5', 'limitFive')"
                            >
                                5
                            </div>
                            <div
                                class="Option"
                                ref="limitTen"
                                @click="changeLimit('10', 'limitTen')"
                            >
                                10
                            </div>
                            <div
                                class="Option"
                                ref="limitFifteen"
                                @click="changeLimit('15', 'limitFifteen')"
                            >
                                15
                            </div>
                            <div
                                class="Option"
                                ref="limitTwenty"
                                @click="changeLimit('20', 'limitTwenty')"
                            >
                                20
                            </div>
                        </div>
                    </div>
                </div>
                <a style="cursor: pointer;" @click="changePage(-1)"
                    >&laquo;</a
                >
                <a
                    style="cursor: pointer;"
                    @click="changePage(-1)"
                    v-if="page != 1"
                    >{{ page - 1 }}</a
                >
                <a style="cursor: pointer;" class="active">{{ page }}</a>
                <a
                    style="cursor: pointer;"
                    @click="changePage(1)"
                    v-if="page != totalPage"
                    >{{ page + 1 }}</a
                >
                <a style="cursor: pointer;" @click="changePage(1)"
                    >&raquo;</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
import "moment/locale/vi";
import { Api } from "@/api/index";
import _ from "lodash";

moment.tz.setDefault("Asia/Ho_Chi_Minh");
moment.locale("vi");

const getListAttendence = Api.get("getListAttendence");
const getListGroup = Api.get("getListGroupV2");
const exportExcelAttendenceEmployee = Api.get("exportExcelAttendenceEmployee")

export default {
    data() {
        var self = this
        return {
            lang: "vn",
            filters: "",

            date: new Date(),
            listGroup: [],
            selectListGroup: "",
            isShow: false,
            imageEdit: "/img/pencil.png",
            imageDelete: "/img/delete.png",

            dateStart: moment().startOf("day").format("X"),
            dateEnd: moment().startOf("day").format("X"),
            page: 1,
            limit: 5,
            totalPage: 1,
            sortAtribute: "checkIn",
            ascendingCheckInValue: false,
            ascendingCheckOutValue: false,
            ascending: false,
            searchValue: "",
            attendanceID: "",

            listAttendenceData: [],
            onlyOneEmployee: false,
            availableExport: false,

            rangeTimeString: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
            rangeTime: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
            showRangePicker: false,
            departmentFilter: "",
            departmentResult: ""
            
        };
    },
    created() {
        this.lang = localStorage.lang == "vn" ? "vi" : "en" 
        getListGroup.get().then((response) => {
            var listGroup = response.data.listResponse;
            var allGroup = localStorage.lang == "vn" ? "Tất cả" : "All"
            listGroup.unshift({ id: "", name: allGroup });
            this.listGroup = listGroup;
        });
        this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
    },

    methods: {
        changeDepartmentFilter(id, name) {
            this.page = 1
            this.departmentFilter = name;
            this.departmentResult = name;
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
            for (var index = 0; index < this.listDepartment.length; index++) {
                if (this.listDepartment[index].id !== id) {
                    this.$refs[id][0].classList.value = ["Option"];
                } else {
                    this.$refs[id][0].classList.value = [
                        "OptionActive"
                    ];
                }
            }
        },
        csvExport() {
            if (this.listAttendenceData.length == 0) {
                this.$swal(this.$t("dashboard.NoData"),"","warning")
            } else {
                this.$swal({
                    title: this.$t("CustomerResponse.CM99999"),
                    allowOutsideClick: true
                });
                this.$swal.showLoading();
                exportExcelAttendenceEmployee.post(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.lang).then(response => {
                        this.$swal.close()
                        const url = URL.createObjectURL(
                            new Blob([response.data], {
                                type: "application/vnd.ms-excel"
                            })
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "report.xlsx");
                        document.body.appendChild(link);
                        link.click();
                    })
            }
        },
        searchForEmployee() {
            this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
        },
        changeSortCheckIn(ascendingCheckInValue) {
            this.sortAtribute = "checkIn"
            this.ascendingCheckIn = ascendingCheckInValue;
            this.ascendingCheckOut = false;
            this.ascending = ascendingCheckInValue;
            this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
        },
        changeSortCheckOut(ascendingCheckOutValue) {
            this.sortAtribute = "checkOut"
            this.ascendingCheckOut = ascendingCheckOutValue;
            this.ascendingCheckIn = false;
            this.ascending = ascendingCheckOutValue;
            this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
        },
        chooseTime() {
            this.showRangePicker = true;
        },
        fetchData(dateStart, dateEnd, groupId, sortAtribute, ascending, searchValue, attendanceID, page, limit) {
            this.listAttendenceData = []
            getListAttendence.get(dateStart, dateEnd, groupId, sortAtribute, ascending, searchValue, attendanceID, page, limit).then((response) => {
                // this.listAttendenceData = response.data.listResponse
                this.totalPage = response.data.pages
                if (response.data.listResponse.length == 0) {
                    this.$notify({
                        timeout: 2500,
                        message: this.$t("dashboard.NoData"),
                        icon: "add_alert",
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: "primary"
                    });
                }
                for (var i = 0; i < response.data.listResponse.length; i++) {
                    var record = response.data.listResponse[i]
                    record.checkIn = this.formatDate(record.checkIn)
                    record.checkOut = this.formatDate(record.checkOut)
                    this.listAttendenceData.push(record)
                }
            });
        },
        getEmployeeData(employeeID) {
            this.attendanceID = employeeID
            this.$router.push({
                name: "Chấm công một nhân viên",
                params: {
                    id: employeeID,
                    dateStart: this.dateStart,
                    dateEnd: this.dateEnd,
                },
            });
        },
        formatDate(data) {
            if (data === "") {
                return this.$t("Employee.NoData");
            } else {
                this.availableExport = true
                var timeStampUnix = moment
                    .unix(data)
                    .format("DD/MM/YYYY HH:mm");
                return timeStampUnix;
            }
        },
        showDatePicker() {
            this.isShow = !this.isShow;
        },
        displayDate() {
            var string = moment(this.date).locale("vi").format("DD/MM");
            return string;
        },

        chooseLimitFilter() {
            this.$refs["wrapLimitFilterOption"].style.position = "absolute";
            this.$refs["wrapLimitFilterOption"].style.display = "block";
            this.$refs["wrapLimitFilterOption"].style.visibility = "visible";
        },
        handleClickOutsideLimitFilter() {
            this.$refs["wrapLimitFilterOption"].style.display = "none";
            this.$refs["wrapLimitFilterOption"].style.visibility = "hidden";
        },

        changeLimit(val) {
            this.page = 1
            this.limit = val
            this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
        },
        changePage(val) {
            if (val == -1 && this.page != 1) {
                this.page--;
                this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
            }
            if (val == 1 && this.page < this.totalPage) {
                this.page++;
                this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
            }
        },

        handleClickOutsideRangePicker(index) {
            if (
                this.showRangePicker == true &&
                index.srcElement._prevClass != "option rangeTime" &&
                index.srcElement._prevClass != "mx-icon-right" &&
                index.srcElement._prevClass != "mx-icon-left" &&
                index.srcElement._prevClass != "mx-icon-double-left" &&
                index.srcElement._prevClass != "mx-icon-double-right" &&
                index.srcElement._prevClass != "mx-calendar-header" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-double-right" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-left" &&
                index.srcElement._prevClass !=
                    "mx-btn mx-btn-text mx-btn-icon-right" &&
                index.srcElement._prevClass !=
                "mx-calendar mx-calendar-panel-date"
            ) {
                if (navigator.userAgent.indexOf("Firefox") == -1) {
                    if (index.path.length == 15) {
                        if (index.path[9]._prevClass != "mx-datepicker-content") {
                            this.showRangePicker = false;
                        }
                    } else {
                        this.showRangePicker = false;
                    }
                } else {
                    if (index.target.parentElement._prevClass != "cell" && index.target.parentElement._prevClass != "cell active" && index.target.parentElement._prevClass != "cell today active") {
                        this.showRangePicker = false;
                    }
                }
            }
        },
    },

    watch: {
        date(val) {
            this.date = val;
            this.dateStart = moment(this.date).startOf("day").format("X");
            this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
        },
        selectListGroup(val) {
            this.selectListGroup = val;
            this.page = 1;
            this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending, this.searchValue, this.attendanceID, this.page, this.limit);
        },
        rangeTime(val) {
            if (val.length != 0) {
                this.page = 1;
                this.specifiedCustomerHistory = false
                if (val[0] == null) {
                    this.rangeTimeString = ["", ""]
                } else {
                    this.dateStart = moment(val[0])
                        .startOf("day")
                        .format("X");
                    this.dateEnd = moment(val[1])
                        .startOf("day")
                        .format("X");

                    this.fetchData(this.dateStart, this.dateEnd, this.selectListGroup, this.sortAtribute, this.ascending,
                        this.searchValue, this.attendanceID, this.page, this.limit);
                    this.showRangePicker = false;
                }
            }   
        }
    },
};
</script>

<style lang="scss">
.profile-image-employee {
    width: 50%;
    margin: 0 auto;
    width: 40px;
    height: 40px;
    margin-left: 27%;

    font-weight: bold;
    // overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    // white-space: normal;
    cursor: default;

    img {
        //border: 1px solid transparent;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-right: 100%;
    }
}
.attendance-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px;
    background-color: #FFFFFF;

    .attendance-main-title {
        font-size: 30px;
        font-weight: bold;
    }

    .attendance-header {
        display: flex;
        justify-content: space-between;

        .ultis-attendance {
            display: flex;
            gap: 10px;

            .search-container-employee {
                background-color: white;
                display: flex;
                align-items: center;
                position: relative;
                gap: 10px;

                .mx-input {
                    padding: 16px 24px;
                    height: 56px;
                    border: 1px solid #8c8c8c;
                    box-sizing: border-box;
                    border-radius: 8px;
                    margin-right: 16px;
                    margin-left: 16px;
                    font-family: "Roboto";
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #1f1f1f;
                    cursor: pointer;
                    &::placeholder {
                        color: #BFBFBF;
                    }
                }
                .base-input {
                    width: 100%;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-size: 15px;
                    border-color: transparent;
                }
                img {
                    width: 20px;
                    height: 20px;
                }
            }
            .btn-search {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 16px 24px;
                width: 256px;
                height: 56px;
                border: 1px solid #8c8c8c;
                box-sizing: border-box;
                border-radius: 8px;
                color: #bfbfbf;
                cursor: pointer;
                .material-icons {
                    color: #8c8c8c;
                }
                input {
                    font-family: "Roboto";
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #1f1f1f;
                    width: 100%;
                    height: 54px;
                    border: 0px solid #fff;
                    &::placeholder {
                        color: #BFBFBF;
                    }
                }
            }
        }

        .btn-export-csv {
            height: 80%;
            padding: 10px;
            font-size: 14px;
            background-color: #4476d9;
            color: white;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .btn-export-csv-unavailable {
            height: 80%;
            padding: 10px;
            font-size: 14px;
            background-color: #7898d8;
            color: white;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.WrapTableAttendence {
    margin-top: 24px;
    table {
        border-spacing: 0 !important;
        width: 100%;
    }
    .tr-head {
        height: 80px;
        background-color: #064B6C;
        color: #fff;
        th {
            width: 200px;
            .material-icons {
                vertical-align: middle;
            }
        }
        th:first-child {
            width: 108px !important;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: "...";
        }
        th:nth-child(2) {
            text-align: center;
            padding-left: 14px;
        }
    }
    td {
        text-align: center;
        height: 64px;
        padding: 8px 12px;
        font-family: "Roboto";
        font-size: 14px;
        color: #272833;
        font-weight: 400;
        //border-bottom: solid 1px #8c8c8c;
        img {
            width: 48px;
            height: 48px;
            margin-right: 8px;
        }
    }
    .border-bottom {
        border-bottom: solid 1px #f0f0f0;
    }
    .border-left {
        border-left: solid 1px #f0f0f0;
    }
    .border-right {
        border-right: solid 1px #f0f0f0;
    }
    td:first-child {
        width: 108px !important;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: "...";
    }
    td:nth-child(2) {
        min-width: 200px;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: "...";
    }
    td:nth-child(3) {
        max-width: 50px;
    }
    td:last-child {
        .material-icons {
            cursor: pointer;
        }
    }
    td:nth-child(6) {
        max-width: 64px !important;
    }
    .pagination {
        display: inline-block;
        border-radius: 5px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .wrapSelectPagination {
            display: flex;
            align-items: center;
            position: relative;
            .seeTable {
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                font-family: "Roboto";
            }
            .SelectPagination {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 60px;
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                font-family: "Roboto";
                padding: 8px 8px;
                cursor: pointer;
                border-radius: 8px;
                margin-left: 16px;
                // &:hover {
                //     background-color: #F6F9FF;
                //     .wrapOptionPagination {
                //         display: block;
                //     }
                // }
                .wrapOptionPagination {
                    position: absolute;
                    display: none;
                    padding: 8px;
                    width: 60px;
                    background: #ffffff;
                    box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
                        0px 3px 4px rgba(10, 31, 68, 0.1);
                    border-radius: 10px;
                    top: 40px;
                    right: 0;
                    z-index: 9;
                    .Option {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 8px 16px;
                        width: 100%;
                        height: 36px;
                        font-family: "Roboto";
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #333333;
                        cursor: pointer;
                        
                    }
                    .OptionActive {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        padding: 8px 16px;
                        width: 100%;
                        height: auto;
                        font-family: "Roboto";
                        background-color: #edf3ff;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #333333;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .pagination a {
        color: black;
        float: left;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        font-family: "Roboto";
        padding: 12px 16px;
        text-decoration: none;
    }

    .pagination a.active {
        color: #2b4bf2;
        border-radius: 5px;
    }

    .pagination a:hover:not(.active) {
        background-color: #f6f9ff;
        border-radius: 5px;
        color: #fff;
    }

    .widthauto {
        width: auto;
    }
}
</style>
