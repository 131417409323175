<template>
    <div class="md-layout">
        <div class="md-layout-item md-medium-size-100 md-size-100">
            <carousel
            :paginationEnabled="true" :paginationActiveColor="'#406652'" 
            :paginationColor="'rgba(300, 300, 300, 1)'"  
            :centerMode=true :perPage="1" :navigationEnabled=true :loop=true
            :scrollPerPage="true" :speed=500>
                <slide v-for="(slide, i) in listImage" :index="i" :key="slide">
                    <img class="example-slide" :src="slide" alt="">
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: "HeatMap",
    components: {
        Carousel,
    },
    props: {
        listImage: {
            type: Array
        },
    }
}
</script>

<style scoped>
.margin-config {
    margin-top: 15.5px !important;
}
.example-slide {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
</style>