<template>
    <div class="manage-employee-container">
        <div class="manage-employee-header">
            <div class="manage-employee-title">
                {{ $t('Employee.ListEmployee') }}
            </div>
            <div class="manage-employee-actions">
                <div class="btn-manage-employee" @click="showModalDepartment">
                    {{ $t('Employee.ManageDepartment') }}
                </div>
                <div class="btn-manage-employee" @click="showModal">
                    {{ $t('Employee.AddEmployee') }}
                </div>
            </div>
        </div>
        <div class="manage-employee-table">
            <div class="WrapSectionEmployee">
                <div class="WrapBtn">
                    <div class="btn-search">
                        <span @click="searchForEmployee()" class="material-icons"
                            >search</span
                        >
                        <input
                            type="text"
                            :placeholder="$t('Customers.SearchHistory')"
                            v-model="searchValue"
                            v-on:keyup.enter="searchForEmployee()"
                        />
                    </div>
                    <div
                        class="btn-filter mr-16 ml-16"
                        v-bind:class="{
                            ActiveButton: filterMode,
                            'btn-filter': !filterMode
                        }"
                        @click="turnFilter()"
                    >
                        <span class="material-icons">filter_alt</span>
                        Filter
                    </div>
                    <div class="btn-filter ml-auto"  @click="downloadFileExcelListEmployee()">
                        <span class="material-icons">download</span>
                        {{ $t("Customers.ExportHistory") }}
                    </div>
                </div>
                <div class="SelectFilter" v-show="filterMode === true">
                    <div
                        class="selectCustom mr-16"
                        @click="chooseGroupFilter()"
                        v-click-outside="handleClickOutsideGroupFilter"
                        v-bind:class="{ ActiveButton: groupAvailable }" 
                    >
                        <span class="Texoverflow">
                        {{ groupResult }}</span>
                        <span class="material-icons">arrow_drop_down</span>
                        <div ref="wrapGroupFilterOption" class="wrapOption">
                            <div
                                v-for="group in listGroup"
                                :key="group.id"
                            >
                                <div
                                    :ref="group.id"
                                    class="Option"
                                    @click="
                                        changeGroupFilter(
                                            group.id,
                                            group.name
                                        )
                                    "
                                >
                                    {{ group.name }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="selectCustom mr-16"
                        @click="chooseDepartmentFilter()"
                        v-click-outside="handleClickOutsideDepartmentFilter"
                        v-bind:class="{ ActiveButton: departmentAvailable }" 
                    >
                        <span class="Texoverflow">
                        {{ departmentOptions.length > 0 ? departmentOptions.toString() : departmentResult }}</span>
                        <span class="material-icons">arrow_drop_down</span>
                        <div ref="wrapDepartmentFilterOption" class="wrapOption">
                            <div
                                v-for="department in listDepartment"
                                :key="department.id"
                                style="padding: 8px 16px"
                            >
                            <input id="chkk" style="cursor: pointer; margin-right: 8px" type="checkbox" :checked="department.isChecked" :value="department.name" @click="selectEmployeeDepartment(department.name)" />
                                {{ department.name }}
                            </div>
                        </div>
                    </div>
                    <div class="CloserFilter">
                        <span class="material-icons" @click="resetFilter()"
                            >filter_alt_off</span
                        >
                    </div>
                </div>
            </div>
            <EmployeeTable 
                @changeSortName="changeSortName"
                @changeSortAge="changeSortAge"
                @changePage="changePage"
                @changeLimit="changeLimit"
                @deleteEmployee="deleteEmployeeModal"
                @deleteEmployeeDone="deleteEmployeeDone"
                :listEmployeeData="listEmployeeData"
                :totalPage="totalPage"
                :page="page"
                />
        </div>
        <ModalSelectOptionEmployee :open="isShowModal" @cancel="closeModal" />
        <modal
            height="auto"
            name="department-modal"
        >
            <DepartmentModal
                @closeDepartmentModal="closeDepartmentModal">
            </DepartmentModal>
        </modal>
        <DeleteEmployeeDialog
            :data="idEmployee"
            :open="isShowDialogDelete"
            @cancel="closeDialogDeleteEmployee"
        />
    </div>
</template>

<script>
import { Api } from "@/api";

import { EmployeeTable } from "@/components";
import { ModalSelectOptionEmployee } from "@/components";
import { DeleteEmployeeDialog } from "@/components";
import { DepartmentModal } from "@/pages" 
import EventBus from "@/EventBus";

const exportExcelListEmployee = Api.get('exportExcelListEmployee');
const getListGroup = Api.get("getListGroupV2");
const getListDepartmentByGroup = Api.get("getListDepartmentByGroup");
const getListEmployeeV3 = Api.get("getListEmployeeV3")

export default {
    mounted() {
        getListGroup.get().then(response => {
            var listGroup = response.data.listResponse;
            this.listGroup = listGroup;
        });
        this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        // EventBus.$on("idEmployeeDelete", (data) => {
        //     console.log("idEmployeeDelete")
        //     this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        // });
    },
    components: {
        EmployeeTable,
        ModalSelectOptionEmployee,
        DeleteEmployeeDialog,
        DepartmentModal
    },

    data() {
        var self = this
        return {
            isShowModal: false,
            isShowDialogDelete: false,
            idEmployee: "",
            filterMode: false,
            groupResult: self.$t("Employee.Location"),
            groupFilter: "",
            groupAvailable: false,
            departmentResult: self.$t("Employee.Department"),
            departmentFilter: "",
            departmentAvailable: false,
            limit: 5,
            page: 1,
            searchValue: "",
            ascending: true,
            sortAtribute: "name",
            listEmployeeData: [],
            departmentOptions: []
        };
    },

    beforeDestroy() {
        this.$swal.close();
    },

    methods: {
        downloadFileExcelListEmployee() {
            if (this.listEmployeeData.length === 0) this.$swal(this.$t('dashboard.NoData'),'', 'warning');
            else {
                this.$swal({
                    title: this.$t('CustomerResponse.CM99999'),
                    allowOutsideClick: true
                });
                this.$swal.showLoading();
                exportExcelListEmployee.post(
                    this.groupFilter,
                    this.sortAtribute,
                    this.ascending,
                    this.searchValue,
                    this.departmentOptions.toString()
                ).then(response =>  {
                    this.$swal.close();
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Danh sách nhân viên.xlsx');
                    document.body.appendChild(link);
                    link.click();
                });
            }
        },
        deleteEmployeeDone(value) {
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit);
        },
        changeGroupFilter(id, name) {
            this.page = 1
            this.groupFilter = name;
            this.groupResult = name;

            for (var index = 0; index < this.listGroup.length; index++) {
                if (this.listGroup[index].id !== id) {
                    this.$refs[id][0].classList.value = ["Option"];
                } else {
                    this.$refs[id][0].classList.value = [
                        'OptionActive'
                    ];
                }
            }
            this.departmentFilter = "";
            this.page = 1;
            this.departmentResult = this.$t("Employee.Department");
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
            var self = this
            getListDepartmentByGroup
                .get(name)  
                .then(response => {
                    self.listDepartment = response.data.listResponse;
                })
                .catch(error => {
                    self.listDepartment = [];
                    self.isSelectedGroup = true;
                    this.$notify({
                        timeout: 2500,
                        message: self.$t("EmployeeResponse."+ error.response.data.message.replace(".","")),
                        icon: "add_alert",
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: "primary"
                    });
                })
        },
        selectEmployeeDepartment(departmentName) {
            this.departmentOptions.includes(departmentName)
                ? this.departmentOptions = this.departmentOptions.filter(el =>  el !== departmentName)
                : this.departmentOptions.push(departmentName);
            this.listDepartment.forEach(element => {
                if (element?.name === departmentName) element.isChecked = !element.isChecked
            });
            this.getListEmployee(this.searchValue, this.departmentOptions.toString(), this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        },
        changeDepartmentFilter(id, name) {
            this.page = 1
            this.departmentFilter = name;
            this.departmentResult = name;
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
            for (var index = 0; index < this.listDepartment.length; index++) {
                if (this.listDepartment[index].id !== id) {
                    this.$refs[id][0].classList.value = ["Option"];
                } else {
                    this.$refs[id][0].classList.value = [
                        "OptionActive"
                    ];
                }
            }
        },
        searchForEmployee() {
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        },
        turnFilter() {
            this.filterMode = this.filterMode !== true;
        },
        showModal() {
            // this.isShowModal = !this.isShowModal;
            this.$router.push({
                name: "Thêm nhân viên",
            });
        },
        closeModal(val) {
            this.isShowModal = val;
        },
        showModalDepartment() {
            this.$modal.show("department-modal");
        },
        closeDepartmentModal(val) {
            this.$modal.hide("department-modal");
        },
        closeDialogDeleteEmployee(val) {
            this.isShowDialogDelete = val;
        },
        deleteEmployeeModal(val) {
            this.isShowDialogDelete = !this.isShowDialogDelete;
            this.idEmployee = val;
        },

        chooseGroupFilter() {
            this.$refs["wrapGroupFilterOption"].style.position =
                "absolute";
            this.$refs["wrapGroupFilterOption"].style.display = "block";
            this.$refs["wrapGroupFilterOption"].style.visibility =
                "visible";
            this.groupAvailable = true;
        },
        handleClickOutsideGroupFilter() {
            this.$refs["wrapGroupFilterOption"].style.display = "none";
            this.$refs["wrapGroupFilterOption"].style.visibility =
                "hidden";
            this.groupAvailable = false;
        },

        chooseDepartmentFilter() {
            this.$refs["wrapDepartmentFilterOption"].style.position =
                "absolute";
            this.$refs["wrapDepartmentFilterOption"].style.display = "block";
            this.$refs["wrapDepartmentFilterOption"].style.visibility =
                "visible";
            this.departmentAvailable = true;
        },
        handleClickOutsideDepartmentFilter(index) {
            if (index) {
                this.$refs["wrapDepartmentFilterOption"].style.display = "none";
                this.$refs["wrapDepartmentFilterOption"].style.visibility =
                    "hidden";
                this.departmentAvailable = false;    
            }
        },
        changeSortName(val) {
            this.sortAtribute = "name,faceID"
            this.ascending = val
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        },
        changeSortAge(val) {
            this.sortAtribute = "birthDate,faceID"
            this.ascending = val
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        },
        changeLimit(val) {
            this.page = 1
            this.limit = val
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        },
        changePage(val) {
            this.page = val
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        },
        getListEmployee(searchValue, departmentFilter, groupFilter, sortAtribute, ascending, page, limit) {
            getListEmployeeV3.get(searchValue, departmentFilter, groupFilter, sortAtribute, ascending, page, limit).then(response => {
                if (response.data.listResponse.length == 0) {
                    this.$notify({
                        timeout: 2500,
                        message: this.$t("dashboard.NoData"),
                        icon: "add_alert",
                        horizontalAlign: "right",
                        verticalAlign: "top",
                        type: "primary"
                    });
                }
                this.listEmployeeData = response.data.listResponse
                this.totalPage = response.data.pages;
                this.handleClickOutsideGroupFilter();
                this.handleClickOutsideDepartmentFilter();
            })
        },

        resetFilter() {
            this.departmentResult = this.$t("Employee.Department");
            this.departmentFilter = "";
            this.departmentOptions = ""

            this.groupResult = this.$t("Employee.Location");
            this.groupFilter = "";

            this.ascending = false
            this.sortAtribute = "name"
            EventBus.$emit("resetFilter", "a");
            this.getListEmployee(this.searchValue, this.departmentFilter, this.groupFilter, this.sortAtribute, this.ascending, this.page, this.limit)
        },
    }
};
</script>

<style lang="scss">
.ActiveButton {
    border: 1px solid #276eff !important;
    color: #276eff !important;
    .material-icons {
        color: #276eff !important;
    }
}
.CloserFilter {
    margin-left: 16px;
    height: 40;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.WrapSectionEmployee {
    width: 100%;
    background-color: #fff;
    // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 32px;
    .WrapBtn {
        display: flex;
        .btn-search {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            width: 256px;
            height: 56px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            color: #bfbfbf;
            cursor: pointer;
            .material-icons {
                color: #8c8c8c;
            }
            input {
                font-family: "Roboto";
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #1f1f1f;
                width: 100%;
                height: 54px;
                border: 0px solid #fff;
                &::placeholder {
                    color: #BFBFBF;
                }
            }
        }
        .btn-filter {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            height: 56px;
            border: 1px solid #8c8c8c;
            box-sizing: border-box;
            border-radius: 8px;
            color: #5a6170;
            font-family: "Roboto";
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #8C8C8C;
            cursor: pointer;
            .material-icons {
                color: #8c8c8c;
            }
        }
        .ml-auto {
            margin-left: auto;
        }
        .btn-delete {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            height: 56px;
            box-sizing: border-box;
            border-radius: 8px;
            color: #ff4343;
            cursor: pointer;
        }
    }
    .SelectFilter {
        width: 100%;
        margin-top: 24px;
        display: flex;
        cursor: pointer;
    }
    .WrapTable {
        margin-top: 24px;
        border: solid 1px #f0f0f0;
        table {
            border-spacing: 0 !important;
            width: 100%;
        }
        .tr-head {
            height: 80px;
            background-color: #064B6C;
            color: #fff;
            th {
                width: 200px;
                .material-icons {
                    vertical-align: middle;
                }
            }
            th:first-child {
                width: 250px !important;
                text-align: left;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: "...";
            }
            th:nth-child(2) {
                text-align: left;
                padding-left: 14px;
            }
        }
        td {
            text-align: center;
            height: 64px;
            padding: 8px 12px;
            font-family: "Roboto";
            font-size: 14px;
            color: #272833;
            font-weight: 400;
            img {
                width: 48px;
                height: 48px;
                margin-right: 8px;
            }
        }
        td:first-child {
            width: 250px !important;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: "...";
        }
        td:nth-child(2) {
            min-width: 200px;
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: "...";
        }
        td:last-child {
            .material-icons {
                cursor: pointer;
            }
        }
        td:nth-child(6) {
            max-width: 64px !important;
        }
        .pagination {
            display: inline-block;
            border-radius: 5px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .wrapSelectPagination {
                display: flex;
                align-items: center;
                position: relative;
                .seeTable {
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: "Roboto";
                }
                .SelectPagination {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 60px;
                    font-size: 14px;
                    line-height: 20px;
                    font-weight: 400;
                    font-family: "Roboto";
                    padding: 8px 8px;
                    cursor: pointer;
                    border-radius: 8px;
                    margin-left: 16px;
                    .wrapOptionPagination {
                        position: absolute;
                        display: none;
                        padding: 8px;
                        width: 60px;
                        background: #ffffff;
                        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
                            0px 3px 4px rgba(10, 31, 68, 0.1);
                        border-radius: 10px;
                        top: 40px;
                        right: 0;
                        z-index: 9;
                        .Option {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px 16px;
                            width: 100%;
                            height: 36px;
                            font-family: "Roboto";
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333333;
                            cursor: pointer;
                          
                        }
                        .OptionActive {
                            display: flex;
                            flex-direction: row;
                            align-items: flex-start;
                            padding: 8px 16px;
                            width: 100%;
                            height: auto;
                            font-family: "Roboto";
                            background-color: #edf3ff;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333333;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .pagination a {
            color: black;
            float: left;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: "Roboto";
            padding: 12px 16px;
            text-decoration: none;
        }

        .pagination a.active {
            color: #2b4bf2;
            border-radius: 5px;
        }

        .pagination a:hover:not(.active) {
            background-color: #f6f9ff;
            border-radius: 5px;
            color: #fff;
        }
    }
}
.manage-employee-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #FFFFFF;
    .manage-employee-header {
        display: flex;
        justify-content: space-between;

        .manage-employee-title {
            font-size: 30px;
            font-weight: bold;
        }

        .manage-employee-actions {
            display: flex;
            gap: 15px;
            font-size: 14px;

            .btn-manage-employee {
                width: 140px;
                height: 45px;
                background-color: #4476d9;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #0069d9;
                }
            }
        }
    }
}
.selectCustom {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    width: 212px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #5a6170;
    border: 1px solid #8c8c8c;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
    
    .wrapOption {
        position: absolute;
        display: none;
        padding: 8px;
        z-index: 9999;
        width: 100% !important;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08),
            0px 3px 4px rgba(10, 31, 68, 0.1);
        border-radius: 10px;
        top: 40px;
        left: -2px;
        max-height: 312px;
        overflow: auto;
        .Option {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: "Roboto";
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
                &:hover {
                    background: #EDF3FF;
                }
        }
        .OptionActive {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 8px 16px;
            width: 100%;
            height: auto;
            font-family: "Roboto";
            background-color: #edf3ff;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #333333;
            cursor: pointer;
        }
    }
    // &:hover {
    //     .wrapOption {
    //         position: absolute;
    //         display: block;
    //     }
    // }
    .Texoverflow {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: "...";
    }
}
</style>
